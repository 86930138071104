#toast-container>div {
  opacity: 1 !important;
}

.table>thead {
  vertical-align: bottom;
  background-color: #716ac8;
  color: #ffff;
}

.table>tbody {
  vertical-align: inherit;
}

.p-sidebar {
  background: #F3F3F9 !important;
}

.group-button {
  float: right;
}

.p-autocomplete-panel .p-autocomplete-items {
  text-align: left !important;
}


.card-header h3 {
  color: #d8d7e1;
  font-size: 20px;
  margin-bottom: 0;
}

.nav-tabs-custom {
  width: 20%;
}

.invalid-field {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fd625e;
}

.p-paginator {
  justify-content: left !important;
}

.fixed-width-start {
  width: 5% !important;
}

.fixed-width-end {
  width: 10% !important;
}

.alignIconsAndNumber {
  text-align: center !important;
}

.p-datatable .p-datatable-thead>tr>th {
  text-align: center !important;
  padding: 1rem 1rem;
  border: 1px solid #e9ecef;
  border-width: 0 0 1px 0;
  font-weight: 600;
  color: #495057;
  background: #f3f3f9 !important;
  transition: box-shadow 0.2s;
}

.p-datatable .p-datatable-tbody>tr {
  background: #ffffff;
  color: #495057;
  transition: box-shadow 0.2s;
  cursor: pointer !important;
}

.p-datatable .p-datatable-tbody>tr:hover {
  background: #f0f1ff !important;
}

.copy-row {
  .copy-icon {
    visibility: hidden;
  }
}

.copy-row:hover {
  .copy-icon {
    visibility: visible;
    color: green;
  }
}


.required {
  color: red;
}

.p-datatable .p-sortable-column:focus {
  box-shadow: none !important;
}

.width-0 {
  width: 0 !important;
}

.headline {
  font-size: 19.4px !important;
}

.anchorClass {
  color: blue;
  text-decoration: underline;
}

.align-right {
  text-align: right;
}

.p-accordion .p-accordion-header:not(.p-disabled) .p-accordion-header-link:focus {
  box-shadow: none !important;
}

.p-autocomplete-loader {
  margin-right: 20px !important
}


.textLength {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 31ch;
}


.ml-2 {
  margin-left: 5px !important;
}

.p-paginator-current {
  position: absolute !important;
  right: 0 !important;
}

.btn-soft-primary:hover,
.btn-soft-primary:focus {
  color: #5156be !important;
  background-color: rgba(81, 86, 190, 0.1) !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

.p-calendar {
  width: 100% !important;
}

.p-datepicker table td>span {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  text-align: left;
}

.p-placeholder {
  color: #ced4da !important;
  font-size: 0.875rem !important;
}

.toast-container {
  z-index: 99999 !important;
}

.goPage {
  width: 50px;
  margin: 0 10px;
  padding-bottom: 6px;
  vertical-align: middle;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
  height: 30px;
  text-indent: calc(100% - 20px)
}


.margin-btm-10 {
  margin-bottom: 10px;
}

.margin-btm-5 {
  margin-bottom: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.editMode {
  border: 1px solid var(--bs-input-border);
  padding: 5px;
  width: 100%;
}


.branding-text {
  color: #187BDC;
}



//Mock design



// .box {
//   width: 200px;
//   height: 200px;
//   background-color: blue;
//   color: white;
//   padding: 20px;
//   text-align: center;
//   font-size: 18px;
// }

// .centered-card {
//   // display: flex;
//   // justify-content: center;
//   padding: 30px;
//   align-items: center;
//   min-height: 100vh;
//   width: 100%;
// }

// .card {
//   background: #FFFFFF;
//   border-radius: 8px !important;
//   border: none !important;
// }

// .card-body {
//   padding: 1rem 1rem 3rem 1rem !important;
// }

// .card-header:first-child {
//   border-radius: 8px 8px 0 0 !important;
// }

// .card-header {

//   position: relative;
//   height: 100px;
//   background: linear-gradient(285.44deg, #45B2FF 0%, #187BDC 82.52%);
//   overflow: hidden;
// }

// .card-header::before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;

//   background-image: url("./assets//images/background.svg");
//   background-size: cover;
//   background-position: center;
//   opacity: 0.4;
// }

// .card-header img {
//   width: 132px;
//   position: relative;
//   top: 40px;
//   bottom: 0;
// }

// .card-title {
//   font-style: normal;
//   font-weight: 600;
//   font-size: 24px;
//   /* line-height: 36px;
//   letter-spacing: -0.4px; */
//   color: #187BDC;
// }

// .custom-input {
//   border: none !important;
//   border-radius: 0 !important;
//   border-bottom: 1px solid #ced4da !important;
//   padding: 0.5rem 0 !important;
//   transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
// }

// .custom-input:focus {
//   outline: none !important;
//   border-color: #80bdff !important;
//   box-shadow: none !important;
// }

// .forgot-password {
//   color: #999;
//   text-align: right;
// }

// .forgot-password a {
//   color: inherit;
//   text-decoration: none;
// }


// .btn-branding {
//   background: #187BDC !important;
//   border-radius: 6px !important;
//   width: 119px !important;
//   color: #D1E5F8 !important;
//   border: none !important;
//   font-size: 14px !important;
// }

// .btn-branding-light {
//   background: #D1E5F8 !important;
//   color: #187BDC !important;
//   border-radius: 6px !important;
//   width: 119px !important;
//   border: none !important;
//   font-size: 14px !important;

// }

// .btn-primary:focus {
//   box-shadow: none !important;
// }

// .btn-tenant {
//   background: #F2F5FF !important;
//   color: #187BDC !important;
//   border-radius: 6px !important;
//   /* width: 119px !important; */
//   border: none !important;
//   font-size: 14px !important;
// }


// .btn-tenant:hover {
//   background: #187BDC !important;
//   color: #F2F5FF !important;
//   border-radius: 6px !important;
//   width: 119px !important;
//   border: none !important;
//   font-size: 14px !important;
// }

// .btn-outline-primary {
//   color: #187BDC !important;
//   border-color: #187BDC !important;
//   font-size: 14px !important;
// }

// .btn-outline-primary:hover {
//   color: #F2F5FF !important;
//   border-color: #187BDC !important;
//   font-size: 14px !important;
// }

.logo_size {
  width: 68%;
}

.country-dropdown {
  width: 25em !important;
}

.break-line {
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
}